<template>
    <auth-layout>
        <h2
            slot="header"
            class="eister-modal__header-text"
        >
            Provide e-mail to recover password
        </h2>
        <form
            slot="body"
            @submit.prevent="validate"
        >
            <div class="eister-modal__input-mask">
                <div class="input-field">
                    <span class="eister-modal__body-info_text">
                        We will send instructions on how to reset your password to your account's e-mail address:
                    </span>
                </div>
                <div class="input-field">
                    <form-input
                        v-model="email"
                        :field.sync="email"
                        name="email"
                        legend="Email address"
                        prepend-icon="EmailOutlineIcon"
                        type="text"
                        :errors="validationMessage($v.email)"
                        :is-valid="$v.email.$dirty && !$v.email.$anyError"
                        @input.native="$v.email.$touch()"
                        @blur.native="$v.email.$touch()"
                    />
                </div>
                <div class="input-field remember-me">
                    Back to
                    <router-link
                        to="/login"
                        class="forgot-password"
                    >
                        Login
                    </router-link>
                    or
                    <router-link
                        to="/register"
                        class="forgot-password"
                    >
                        Register
                    </router-link>
                </div>
            </div>
            <custom-button
                default
                :disabled="isFormValid"
                class="button btn-base_colored"
                :loading="loading"
                :type="'submit'"
                width="100%"
            >
                Send
            </custom-button>
        </form>
    </auth-layout>
</template>

<script>
import AuthLayout from '@/layouts/AuthLayout';

// Validation
import formValidationMixin from '@/mixins/formValidationMixin.js'
import { validationMixin } from 'vuelidate';
import { validationMessage } from 'vuelidate-messages'
import { validationRules } from '@/validation/ForgotPassword/Rules'
import { formMessages } from '@/validation/ForgotPassword/Messages'

// store
import { createNamespacedHelpers } from 'vuex'
import {
    ACTION_RESET_PASSWORD
} from '@/store/modules/auth/action-types.js'

const { mapActions: mapAdminActions } = createNamespacedHelpers('auth')

export default {
    name: 'IndexVue',
    components: {
        AuthLayout
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data() {
        return {
            email: '',
            loading: false
        }
    },
    computed: {
        isFormValid() {
            return this.$v.$invalid
        }
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapAdminActions([ACTION_RESET_PASSWORD]),
        async submit() {
            this.loading = true
            await this[ACTION_RESET_PASSWORD]({
                email: this.email
            })
                .then(() => {
                    this.$bus.$emit('showSnackBar', 'Please check your e-mail for instructions.', 'success');
                    this.$router.push({ name: 'login' })
                })
                .catch((e) => {
                    console.log(e)
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>

<style scoped>
    .forgot-password {
        color: $orange;
    }
</style>
